<template>
    <w-form
        v-if="item && la.item"
        @submit.prevent="submitted"
        @changed="may_submit = true"
        class="live-receptionist mx-auto"
        data-discard="true"
        data-test-id="lr"
        autocomplete="off"
        data-lpignore="true"
    >
        <HelpText v-if="!['avo', 'avo_master'].includes($branding.data.code) && !preselected_vendor_id" class="py-0">
            {{ $lang.t('la.pdc-offers-both-asnwer-bot', 'Phone.com offers both live reception services and our virtual reception service, the AI-Connect.  Live receptionist service is perfect for giving your callers a white glove experience.  The Answer Bot is ideal for routing callers, stopping SPAM, and scheduling appointments.') }}
        </HelpText>
        <div v-if="!['avo', 'avo_master'].includes($branding.data.code)">
            <template v-if="!preselected_vendor_id && !(preselected_code && item.code && item.code === preselected_code)">
                <div :class="classes.field_titles" class="mb-2" data-test-id="lr-provider-title">
                    {{ $lang.t('app.select-service-type', 'Select your service type') }}
                </div>
                <VendorsSelector
                    :value="item.vendor"
                    :item="item"
                    @input="on_vendor_input($event)"
                    :disabled="!!item.id || disable_input()"
                    class="provider-selector"
                    data-test-id="lr-vendor-selector"
                />
            </template>
            <template v-if="item.vendor && (item.vendor.id === 1 || item.vendor.id === 8)">
                <LRCallWarning />
                <ExpertCallButton class="mt-1"/>
            </template>
            <template v-if="vendor_name() === 'ONVEGO'">
                <OnvegoCallWarning />
                <HelpText v-if="item.vendor && vendor_name() === 'ONVEGO'">
                    {{
                        $lang.t('la.great-choice-when-you-apply-this-script-to-a-phone-number', 'Great choice! When you apply this script to a phone number, user, or extension, the AI-Connect will manage your calls 24x7.')
                    }}
                </HelpText>
            </template>
            <CodesSelector
                v-if="
                    item.vendor
                    && item.vendor.codes
                    && (vendor_name() !== 'ONVEGO' || has_onvego_apointments)
                    && (!preselected_code || (item.code && preselected_code !== item.code))
                "
                v-model="item.code"
                @input='on_codes_input'
                :vendor="item.vendor"
                :la="la"
                :onvego="ONVEGO"
                :avo="AVO"
                :has_onvego_apointments="has_onvego_apointments"
                :disabled="disable_input()"
                :rules="[$validation.required()]"
                :key="`${item.vendor.id}-${item.code}-${!!item.vendor.codes}`"
                hide-details="auto"
                data-test-id="lr-plan-select"
            />
        </div>
        <template v-if="item.code">
            <div>
                <v-divider v-if="!['avo', 'avo_master'].includes($branding.data.code)" class="light-divider provider-divider" />
                <div id="lr-title" class="w-h6 info--text text--darken-1">{{ title || $lang.t('lr.your-script', 'Your script') }}</div>
                <div class="w-body-2 mb-7 mt-2" data-test-id="lr.tiitle-help-text">
                    <template v-if="item.vendor.id === 4">
                        {{ $lang.t('lr.configure-how-intelligent-answer-bot-will-great-calls', 'Configure how the AI-Connect will present your company and redirect calls')}}
                    </template>
                    <template v-else>
                        {{ $lang.t('lr.configure-how-ive-receptionist', 'Configure how Live receptionist will present your company and redirect calls.') }}
                    </template>
                </div>
                <LRCallWarning v-if="['avo', 'avo_master'].includes($branding.data.code) && !item.id" />
            </div>
            <div class="max-width-630 mx-auto">
                <div :class="classes.field_titles" class="mb-3" data-test-id="create-new-script-title">
                {{ $lang.t('lr.give-name', 'Give your script a name') }}
            </div>
            <w-text-field v-model="item.name" :disabled="disable_input()" :placeholder="$lang.t('lr.enter-script-name', 'Enter a name for your script')"
                type="text" :rules="[$validation.required()]" class="w-100" hide-details="auto" data-test-id="lr-name-input" />
            <template v-if="vendor_name() !== 'ONVEGO'">
                <div :class="classes.field_titles" class="mb-2" data-test-id="lr-bus-profile-title">
                    {{ $lang.t('lr.business-profile', 'Business profile') }}
                </div>
                <w-text-field
                    v-model="item.business_name"
                    :placeholder="$lang.t('app.business-name', 'Business name')"
                    :disabled="disable_input()"
                    :rules="[$validation.required()]"
                    class="w-100"
                    hide-details="auto" data-test-id="lr-businesss-name-input"
                    autocomplete="new-password" data-lpignore="true"
                />
            </template>
            <template v-if="vendor_name() !== 'ONVEGO'">
                <div :class="classes.ordinary_field_title" data-test-id="lr-business-description-title">
                    {{ $lang.t('lr.business-description', 'Business description') }}
                    <InfoLink max-width="300">
                        {{ $lang.t('lr.business-description-help', 'Please provide the phonetic spelling of your business name. (Example: For Phone.com you would write ‘Fone Dot Com’). Briefly describe what callers will likely be asking about, such as services provided, work hours and/or scheduling availability. Provide a contact number where we can call you to resolve any service matters that might occur.') }}
                    </InfoLink>
                </div>
                <TextEditor
                    v-model="item.business_description"
                    :disabled="disable_input()"
                    id="business_description"
                    :rules="vendor_name() === 'ANSWERNET' ? [$validation.required()] : []"
                    data-test-id="lr-business-description-input"
                />
                <div :class="classes.ordinary_field_title" data-test-id="lr-address-title">
                    {{ $lang.t('lr.business-address', 'The physical address you allow to disclose to callers') }}
                </div>
                <Address
                    v-model="item.business_contact.address"
                    :disabled="disable_input()"
                    :vendor="item.vendor"
                    :key="`address-${item.vendor.id}`"    
                />
                <div :class="classes.ordinary_field_title" data-test-id="lr-fax-num-title">
                    {{ $lang.t('app.fax-number', 'Fax number') }}
                </div>
                <Tel
                    :disabled="disable_input()"
                    :value="item.business_contact.fax"
                    @changed="item.business_contact.fax = $event.clean_number"
                    :required="false"
                    data-test-id="lr-tel-input"
                />
            </template>
            <template v-if="(vendor_name() !== 'ONVEGO' || has_onvego_apointments)">  <!-- & item.code !== 19087 -->
                <div :class="classes.ordinary_field_title" data-test-id="lr-appointments-title">
                    {{ $lang.t('lr.business-would-you-like-to-take-appointments', 'Would you like to take appointments?') }}
                </div>
                <w-radio-group
                    v-model="item.appointments_enabled"
                    disable="disable_input()"
                    hide-details="auto"
                    row
                    class="mt-n4"
                    data-test-id="lr-appointments-radios"
                    :key="`appointments-enabled-${item.appointments_enabled}`"
                >
                    <v-radio color="secondary" type="radio" :label="$lang.t('app.yes', 'Yes')" :value="true" data-test-id="lr-take-appointments-yes"/>
                    <v-radio color="secondary" type="radio" :label="$lang.t('app.no', 'No')" :value="false" data-test-id="lr-take-appointments-no"/>
                </w-radio-group>
                <div v-if="item.appointments_enabled">
                    <div :class="classes.ordinary_field_title" data-test-id="lr-calendar-title" id="calendar">
                        {{ $lang.t('app.calendar', 'Calendar') }}
                    </div>
                    <Appointments
                        :style="{'display': la.appointments_loading ? 'none' : 'block'}"
                        :is_onvego="vendor_name() === 'ONVEGO'"
                        :iframe_url="vendor_iframe_url()"
                        v-model="item.appointments_description"
                        :disabled="disable_input()"
                        :rules="vendor_name() === 'ANSWERNET' ? [$validation.required()] : []"
                        :item="item"
                        :placeholder="$lang.t('lr.business-appointment-help', 'Provide a link to your calendar service. Not all calendar services are supported. If needed, we can help you set up Appointed, a scheduling service that should be able to sync to your existing calendar.')"
                        :rows="!item.appointments_description && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                        @change="may_submit = true;"
                        class="w-100"
                        auto-grow
                        hide-details="auto"
                        data-test-id="lr-calendar-input"
                        :key="`calendar-${item.vendor.id}-${item.appointments_enabled}`"
                    />
                </div>
            </template>
            <div :class="vendor_name() === 'ONVEGO' ? classes.field_titles : classes.ordinary_field_title" data-test-id="lr-greeting-title">
                {{ $lang.t('app.greeting', 'Greeting') }}
            </div>
            <div v-if="vendor_name() === 'ONVEGO'" :class="classes.field_explanation">
                {{$lang.t('lr.greetings-subtitle-v2', 'Enter the greeting for your callers. You can include information about your business.')}}
            </div>
            <w-textarea
                v-model="item.greeting"
                :disabled="disable_input()"
                :rows="!item.greeting && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                auto-grow
                @input="on_greeting_input"
                :rules="[$validation.required(), $validation.maxlength(256)]"
                :placeholder="$lang.t('la.greeting-placeholder', 'Enter the greeting your receptionist will read to your callers. Please use phonetic spelling where needed.  (Example: For Phone.com you would write ‘Fone Dot Com’)')"
                hide-details="auto"
                class="w-100"
                data-test-id="lr-greeting-input"
            />
            <OnvegoGreeting
                v-if="vendor_name() === 'ONVEGO'"
                :greeting="item.greeting"
                :contacts="item.contacts"
                :code="item.code"
                :appointments_enabled="item.appointments_enabled"
                :key="`onvego-greeting-${item.greeting}-${item.code}-${JSON.stringify(item.contacts)}-${!!item.appointments_enabled}`"
            />
            <!-- <GreetingInput
                v-model="item.greeting"
                :rows="!item.greeting && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                :item="item"
                :prompt_title_classes="classes.field_titles"
                :prompt_explanation_classes="classes.field_explanation"
            /> -->
            <template v-if="vendor_name() !== 'ONVEGO'">
                <div :class="classes.ordinary_field_title" data-test-id="lr-chi-title">
                    {{ $lang.t('lr.business-call-handling-instructions', 'Call handling instructions') }}
                    <InfoLink max-width="300">
                        {{ $lang.t('la.business-call-handling-notes-help', 'Example: When transferring a call, always conduct using ‘warm’ transfer. If no one answers, direct caller to recipient’s voicemail.') }}
                    </InfoLink>
                </div>
                <TextEditor
                    v-model="item.call_handling_notes"
                    :disabled="disable_input()"
                    :rules="vendor_name() === 'ANSWERNET' ? [$validation.required()] : []"
                    id="call_handling_notes"
                    data-test-id="lr-chi-input"
                />
            </template>
            <div :class="classes.field_titles" data-test-id="lr-contacts-title">
                {{ $lang.t('app.contacts-and-routing', 'Contacts and routing') }}
                 <InfoLink
                    key="bottom-help-text"
                    data-test-id="lr-contacts-info-link"
                    max-width="300"
                >
                    <template v-slot:icon>
                        <v-icon size="19" class="pb-1" color="#6E7A82">
                          $vuetify.icons.infotip_stylized
                        </v-icon>
                    </template>
                    {{ $lang.t(
                        'live-answer.contacts-info-text',
                        'Please provide the name and best way to reach any contacts mentioned under your call handling instructions so we know who to contact and how to reach them. You can add more than one way to reach each contact.'
                    ) }}
                </InfoLink>
            </div>
            <div :class="classes.field_explanation">
                 {{ $lang.t('lr.contacts-help-text-1-answer-bot', 'Contacts are the people or teams to which the service will send your calls. You can set them up using a Phone.com phone number, extension, or an outside number.')}}
            </div>
            <div :class="classes.field_explanation">
                {{ $lang.t('lr.contacts-help-text-2-answer-bot', 'By default, calls will be handled with a “Warm transfer.” The caller will provide their name, and it will be announced to the recipient.  The recipient can accept or reject the call.  If the call is not answered, it will be directed to the next destination (Phone.com voicemail by default).')}}
            </div>
            <div :class="classes.field_explanation">
                {{ $lang.t('lr.contacts-help-text-3-answer-bot', 'If you want the service to send the call to its destination without any screening, uncheck the “Warm transfer” box.  Do this if you want callers to leave voicemail messages on your mobile device voicemail rather than your Phone.com voicemail.')}}
            </div>
            <div class="live-answer-contacts-container">
                <Contact
                    v-for="(_, i) in item.contacts"
                    :key="(item.contacts[i]._unique_key || i) + i"
                    :disabled="disable_input()"
                    :_contact="item.contacts[i]"
                    :index="i + 1"
                    :deletable="item.contacts.length > 1"
                    @addDestination="addDestination(i)"
                    @remove="removeContact(i)"
                    @changed="item.contacts[i] = $event"
                    class="contacts-item"
                    :class="item.contacts.length === i + 1 ? 'last-contact-item' : ''"
                    :data-test-id="`lr-contact-${i}`"
                />
                <w-btn
                    v-if="item.enabled && !la.appointments_loading"
                    @click="addContact"
                    color="primary"
                    class="font-weight-bold pl-0 mt-4"
                    text
                    :disabled="!item.contacts.every((c) => c.first_name && c.last_name && c.destinations.every((d) => d.type && d.destination))"
                    data-test-id="lr-add-contact-btn"
                >
                    <v-icon class="mr-1">mdi-plus-circle</v-icon> {{ $lang.t('contacts.add-new-contact', 'Add new contact') }}
                </w-btn>
                <DefaultContact
                    v-if="has_IDK_feature && item && item.vendor && vendor_name() === 'ONVEGO'"
                    :contacts="item.contacts"
                    :value="default_contact_value"
                    :enable_value="!item.id ? true : (!!default_contact_value || default_contact_value === 0)"
                    :disabled="disable_input()"
                    @input="on_default_contact_input"
                    :key="default_contact_value"
                />
            </div>
            <DeleteDefaultContactModal
                v-if="delete_default_contact !== null"
                :value="true"
                :contacts="item.contacts"
                :deleting_contact_index="delete_default_contact"
                @input="on_delete_default_contact_modal_input"
                @confirmed="do_remove_contact"
                @changed="on_default_contact_input"
            />
            <div class="m-top-50 d-flex justify-center">
                <template v-if="item.id">
                    <w-btn
                        v-if="disable_input()"
                        @click.prevent="reactivate_confirmation_modal = true"
                        color="success"
                        class="white--text"
                        data-test-id="lr-reactivate-btn"
                    >
                        {{ $lang.t('lr.enable-script', 'Enable script') }}
                    </w-btn>
                    <w-btn
                        v-if="item.enabled && !la.appointments_loading"
                        @click.prevent="script_to_disable = JSON.parse(JSON.stringify(item))"
                        color="error base"
                        data-test-id="lr-deactivate-btn"
                    >
                        {{ $lang.t('app.disable-script', 'Disable script') }}
                    </w-btn>
                    <div class="buttons-vertical-divider mx-5"></div>
                </template>
                <CancelChanges class="mr-5" @click:cancel="$emit('click:cancel')"/>
                <w-btn v-if="item.enabled && !la.appointments_loading" :disabled="!may_submit || la.appointments_loading" type="submit" color="success" class="white--text" data-test-id="lr-add-submit-btn">
                    {{ $lang.t('app.save-changes', 'Save changes') }}
                </w-btn>
            </div>
            </div>
        </template>
        <DisableScriptModal
             v-if="script_to_disable"
            :callback="la.deactivate.bind(la)"
            :value="true"
            :model="la"
            @processed="on_disabled"
            @input="(v) => !v ? script_to_disable = null : null"
            :item="script_to_disable"
            @close="script_to_disable = null"
            :key="`disable-${script_to_disable['id']}`"
        />
        <EnableScriptModal
            v-if="reactivate_confirmation_modal"
            :vendor_id="item && item.vendor ? item.vendor.id : null"
            @confirmed="reactivate_script"
            @close="reactivate_confirmation_modal = false"
        />
        <ConfirmAdditionalChargesModal
            v-model="show_confirm_additional_charges_modal"
            :item="item"
            @continue="on_confirm"
            @close="on_not_confirmed"
        />
        <IabBillingModal
            v-model="show_iab_billing_modal"
            @continue="on_confirm_iab"
            @close="on_not_confirmed_iab"
        />
        <DefaultModal
            v-if="duplicated_contacts_names.length"
            @close="duplicated_contacts_names = []"
            :value="true"
            max-width="400"
            data-test-id="lr-duplicated-contact-modal"
        >
            <div class="w-body-2">
                {{ $lang.t('lr.issue-saving', 'There was an issue saving')}}:
                <div class="error--text" v-html="duplicated_contacts_names.join('<br/>')" />
                <div class="mt-5">
                    {{ $lang.t('lr.due-response-system', 'Due to our voice response system, there cannot be more than one contact with the same first and last name. Please update your contacts so they each have a different name.')}}
                </div>
            </div>
            <div class="mt-8 d-flex justify-center">
                <w-btn @click="duplicated_contacts_names = []" color="primary" class="white--text"
                    data-test-id="lr-reactivate-modal-cancel-btn">
                    {{ $lang.t('app.continue', 'Continue') }}
                </w-btn>
            </div>
        </DefaultModal>
        <!-- <DefaultModal v-if="show_plan_change_modal" :value="true" width="350">
            <template v-slot:title>
                {{show_plan_change_modal === 19088 ? $lang.t('app.upgrade-from-x-to-x', 'Upgrade from ${} to ${}', [4.99, 19.99]) : $lang.t('downgrade-from-x-to-x', 'Downgrade from ${} to ${}', [19.99, 4.99])}}
            </template>
            <template v-if="show_plan_change_modal === 19088">
                {{$lang.t('lr.you-are-upgrading-your-call-routing-iab-to-the-version-with-appointments', 'You are upgrading your Call routing AI-Connect to the version with Appointments. The ${} per month price will be applied to your account based on your payment period - monthly or annually.', [19.99])}}
            </template>
            <template v-if="show_plan_change_modal === 19087">
            {{$lang.t('lr.you-are-downgrading-your-Call-routing-iab-with-Appointments', 'You are downgrading your Call routing AI-Connect with Appointments to the version with only Call routing. The ${} per month price will be applied to your account based on your payment period - monthly or annually', [4.99])}}
            </template>
            <template v-slot:buttons>
                <w-btn @click="on_show_plan_change_modal_close" color="secondary">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
                <w-btn @click="on_show_plan_change_confirmation" color="success">{{$lang.t('app.proceed', 'Proceed')}}</w-btn>
            </template>
        </DefaultModal> -->
    </w-form>
</template>

<script>
    import {getFeatureEnabled} from 'feature-flag';
    import { randomNumber } from 'random-generator';

    import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';
    import FormValidation from '../../../console/src/libs/validation/form-rules';

    import Address from './Address.vue'
    import Contact from './Contact.vue';
    import Appointments from './Appointments.vue';
    import CodesSelector from './CodesSelector.vue';
    // import GreetingInput from './GreetingInput.vue';
    import DefaultContact from './DefaultContact.vue';
    import OnvegoGreeting from './OnvegoGreeting.vue';
    import LRCallWarning from './call-warnings/lr.vue';
    import VendorsSelector from './VendorsSelector.vue';
    import ExpertCallButton from './ExpertCallButton.vue';
    import EnableScriptModal from './EnableScriptModal.vue';
    import DisableScriptModal from './DisableScriptModal.vue';
    import OnvegoCallWarning from './call-warnings/onvego.vue';
    import DeleteDefaultContactModal from './DeleteDefaultContactModal.vue';
    import ConfirmAdditionalChargesModal from './ConfirmAdditionalChargesModal.vue';
    import IabBillingModal from './IabBillingModal.vue';
    import Tel from '../../../console/src/components/elements/Tel.vue';
    import InfoLink from '../../../console/src/components/elements/InfoLink.vue';
    import HelpText from '../../../console/src/components/elements/HelpText.vue';
    import FormInput from '../../../console/src/components/elements/form/FormInput.vue';
    import SectionTitle from '../../../console/src/components/elements/SectionTitle.vue';
    import CancelChanges from '../../../console/src/components/elements/CancelChanges.vue';
    import TextEditor from '../../../console/src/components/elements/form/text-editor.vue';
    import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
    import ConfirmationModal from '../../../console/src/components/elements/modal/ConfirmationModal.vue';
    export default {
    props: {
      _la: {
        type: Object
      },
      title: {
        type: String
      },
      preselected_vendor_id: {
        type: Number,
        required: false,
      },
      preselected_code: {
        type: Number,
        required: false,
      },
    },
    components: {
        Tel,
        Address,
        Contact,
        InfoLink,
        HelpText,
        FormInput,
        TextEditor,
        Appointments,
        DefaultModal,
        SectionTitle,
        CodesSelector,
        LRCallWarning,
        CancelChanges,
        OnvegoGreeting,
        // GreetingInput,
        DefaultContact,
        VendorsSelector,
        IabBillingModal,
        ExpertCallButton,
        OnvegoCallWarning,
        ConfirmationModal,
        EnableScriptModal,
        DisableScriptModal,
        DeleteDefaultContactModal,
        ConfirmAdditionalChargesModal,
    },
    data() {
        return {
            la: null,
            item: null,
            codes: null,
            form_validation: FormValidation,
            script_to_disable: null,
            reactivate_confirmation_modal: false,
            duplicated_contacts_names: [],
            provider_explanation: true,
            may_submit: false,
            ONVEGO: {
                id: 4,
                prefered_plan: 19087,
            },
            AVO: {
                id: 1,
                prefered_plan: 19086,
            },
            ANSWERNET: {
                id: 8,
            },
            classes: {
                main_titles: 'w-body-1 info--text text--darken-1 field-title',
                field_titles: 'w-body-1 info--text text--darken-1 field-title',
                field_explanation: 'w-body-2 field-explanation',
                ordinary_field_title: 'w-body-2 field-title-ordinary',
            },
            show_confirm_additional_charges_modal: false,
            show_iab_billing_modal: false,
            confirmed: false,
            original_code: null,
            has_onvego_apointments: false,
            has_IDK_feature: false,
            delete_default_contact: null,
            show_plan_change_modal: false,
            onvego_appointments_ft: 'console.show_onvego_appointments',
      };
    },
    beforeMount() {
        const allInputs = document.querySelectorAll('input');
        for (const input of allInputs) {
            input.dataset.lpignore = true; // this should prevent lastPass chrome ext to work on these field, but it doesnt for now
        }
    },
    async created() {
        this.$data.la = this.$props._la;
        this.$data.item = JSON.parse(JSON.stringify(this.$data.la.item));

        this.$data.has_onvego_apointments = await getFeatureEnabled(this.$data.onvego_appointments_ft);
        this.$data.has_IDK_feature = await getFeatureEnabled('console.i_dont_know');

        if (!this.$data.item.id && !this.$data.item.vendor && this.$props.preselected_vendor_id) {
            // const vendor = await this.$data.la.getVendor(this.$props.preselected_vendor_id);
            let vendors =  new LiveAnswerVendor()
            await vendors.init()
            this.on_vendor_input(vendors.getVendor(this.$props.preselected_vendor_id))
            this.$forceUpdate();
        } else if (!this.$data.item.id && !this.$props.preselected_vendor_id && this.$props.preselected_code) {
            const vendors = await this.$data.la.getVendors();
            if (
                vendors
                && vendors.items
                && vendors.items.length
            ) {
                const vendor = vendors.items.find((x) => x.codes && x.codes.find((y) => y.code === this.$props.preselected_code))
                if (vendor) {
                    this.on_vendor_input(vendor)
                    this.$forceUpdate();
                } else {
                    throw new Error('Invalid code value')
                }
            }
        } 
        
        this.$data.original_code = this.$data.item ? this.$data.item.code : null;
        if (this.$data.item && this.$data.item.contacts && this.$data.item.contacts.length) {
            this.$data.item.contacts = this.$data.item.contacts.map((x) => {
                x._unique_key = randomNumber();
                if (x.destinations && x.destinations.length) {
                    x.destinations = x.destinations.map((d) => {
                        d._unique_key = randomNumber();
                        return d;
                    });
                }
                return x;
            });
        }

        if (
            this.$data.item
            && ['avo', 'avo_master'].includes(this.$branding.data.code)
            // && (!this.$data.item.vendor || (this.$data.item.vendor && this.vendor_name() === 'AVO'))
            // && (!this.$data.item.code || this.$data.item.code === this.$data.AVO.prefered_plan)
        ) {
            this.$data.item.vendor = {id: this.$data.AVO.id};
            this.$data.item.code = this.$data.AVO.prefered_plan;
            this.prefill_script_name(1);
        }
        if (this.$data.item) this.$data.item.has_onvego_apointments = this.$data.has_onvego_apointments;

        if (!this.$data.item.id) this.$data.may_submit = true;
    },
    methods: {
        on_confirm() {
            this.$data.confirmed = true;
            this.$data.show_confirm_additional_charges_modal = false;
            this.submitted();
        },
        on_not_confirmed() {
            this.$data.show_confirm_additional_charges_modal = false;
            this.$data.confirmed = false;
        },
        on_confirm_iab () {
            this.$data.confirmed = true;
            this.$data.show_iab_billing_modal = false;
            this.submitted();
        },
        on_not_confirmed_iab() {
            this.$data.show_iab_billing_modal = false;
            this.$data.confirmed = false;
        },
        // on_appointments_enabled_input(val) {
        //     if (
        //         this.$data.item.vendor
        //         && this.vendor_name() === 'ONVEGO'
        //         && this.$data.has_onvego_apointments
        //         && this.$data.item.code
        //     ) {
        //         if (val && this.$data.item.code === 19087) {
        //             this.$data.show_plan_change_modal = 19088;
        //         } else if (!val && this.$data.item.code === 19088) {
        //             this.$data.show_plan_change_modal = 19087;
        //         } else {
        //             // possible scenario: we created a script before this change and we put the only 19087 plan and enabled appointments, probably only test scripts
        //             this.$data.item.appointments_enabled = val;
        //         }
        //     } else {
        //         this.$data.item.appointments_enabled = val;
        //     }
        // },
        // on_show_plan_change_modal_close() {
        //     this.$data.show_plan_change_modal = false;
        // },
        // on_show_plan_change_confirmation() {
        //     this.$data.item.appointments_enabled = this.$data.show_plan_change_modal === 19088;
        //     this.$data.item.code = this.$data.show_plan_change_modal;
        //     this.$data.show_plan_change_modal = false;
        // },
        on_default_contact_input(contact_index) {
            if (typeof contact_index === 'number') {
                for (let i = 0; i < this.$data.item.contacts.length; i++) {
                    if (i === contact_index) {
                        this.$data.item.contacts[contact_index].is_default = true;
                        if (this.$data.item.id && this.$data.item.contacts[contact_index].id) {
                            this.$data.item.default_contact_id = this.$data.item.contacts[contact_index].id;
                        }
                    } else {
                        this.$data.item.contacts[i].is_default = false;
                    }
                }
            } else {
                for (let i = 0; i < this.$data.item.contacts.length; i++) {
                    this.$data.item.contacts[i].is_default = false;
                }
                this.$data.item.default_contact_id = null;
            }
        },
        on_delete_default_contact_modal_input(v) {
            if (!v) this.$data.delete_default_contact = null;
        },
        on_greeting_input(val) {
            if (!val) val = '';
            this.$data.item.greeting = val.replace(/\n/g, ' ');
        },
        on_codes_input(code) {
            this.$data.la.gtm_data_push('PDC_Chosen_Script_or_Plan', code);
            if (this.$data.has_onvego_apointments && this.$data.item.vendor && this.vendor_name() === 'ONVEGO') {
                const code_with_calendar = 19088;
                this.$data.item.appointments_enabled = code === code_with_calendar;
            }
        },
        is_form_valid() {
            this.$data.la.alert = null;
            if (!this.$data.item.greeting) {
                this.$data.la.alert = {
                    level: 'error',
                    message: this.$lang.t('lr.greeting-required', 'Greeting field is required.'),
                };
            }
            const contacts_error = {
                level: 'error',
                message: this.$lang.t('lr.contact-destination-required', 'At least one contact and one destination required.'),
            };
            if (!this.$data.item.contacts) {
                this.$data.la.alert = contacts_error;
            }
            if (!this.$data.item.contacts.length) {
                this.$data.la.alert = contacts_error;
            }
            for (let i = 0; i < this.$data.item.contacts.length; i++) {
                const c = this.$data.item.contacts[i];
                if (!c.first_name || !c.last_name) {
                    this.$data.la.alert = {
                        message: this.$lang.t('lr.contacts-requiredmust-have-first-and-last-name', 'Contact #{} should have first and last name.', [i + 1]),
                        level: 'error',
                    };
                }
                if (!this.$data.la.alert && !c.destinations.length) {
                    return this.$data.la.alert = {
                        message: this.$lang.t('lr.contacts-destinations-required', 'Contact #{} requires at least one destination.', [i + 1]),
                        level: 'error',
                    };
                }
            }
            const invalid_destinations_index = this.$data.item.contacts.findIndex((x) => !x.destinations.every((y) => y.type && y.destination));
            if (!this.$data.la.alert && invalid_destinations_index > -1) {
                this.$data.la.alert = {
                    level: 'error',
                    message: this.$lang.t('lr.contacts-destination-type-required', 'Contact #{} has an incomplete destination.', [invalid_destinations_index + 1]),
                };
            }
            if (!this.$data.la.alert) {
                for (let i = 0; i < this.$data.item.contacts.reverse().length; i++) {
                    for (let j = 0; j < this.$data.item.contacts[i].destinations.length; j++) {
                        const d = this.$data.item.contacts[i].destinations[j];
                        if (d.type === 'email') {
                            if (this.$validation.email()(d.destination) !== true) {
                                this.$data.la.alert = {
                                    level: 'error',
                                    message: this.$lang.t('lr.invalid-email-for-destination', 'Contact #{} / destination #{} has invalid email format.', [i + 1, j + 1]),
                                };
                            }
                        }
                        if (d.type === 'phone_number') {
                            if (this.$validation.phone_number()(d.destination) !== true) {
                                this.$data.la.alert = {
                                    level: 'error',
                                    message: this.$lang.t('lr.invalid-phone-for-destination', 'Contact #{} / destination #{} has invalid phone number.', [i + 1, j + 1]),
                                };
                            }
                        }
                    }
                }
            }

            if (this.$data.la.alert) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.$data.la.hide_alert(5);

                return false;
            }
            return true;
        },
        async submitted() {
            if (this.vendor_name() === 'ONVEGO') {
                this.$data.item.business_description = '';
                this.$data.item.business_contact.address = null;
                this.$data.item.business_contact.fax = '';
                this.$data.item.call_handling_notes = '';
            }
            this.$data.item.contacts = this.$data.item.contacts.map((contact) => {
                contact.first_name = contact.first_name.trim().replace(/  +/g, ' ');
                contact.last_name = contact.last_name.trim().replace(/  +/g, ' ');
                return contact;
            });
            if (!this.is_form_valid()) {
                return null;
            }

            const contacts_names = this.$data.item.contacts.map((x) => `${x.first_name} ${x.last_name}`);
            const duplicated_contact_names = contacts_names.filter((x, index) => contacts_names.indexOf(x) !== index);
            if (duplicated_contact_names.length) {
                return this.$data.duplicated_contacts_names = duplicated_contact_names;
            }
            if (
                !['avo', 'avo_master'].includes(this.$branding.data.code)
                && this.$data.item
                && this.$data.item.vendor
                && this.$data.item.vendor.id === 1
                && this.$data.item.code
                && this.$data.original_code !== this.$data.item.code
                && !this.$data.confirmed
            ) {
                return this.$data.show_confirm_additional_charges_modal = true;
            }

            if (
                this.$data.item
                && this.$data.item.vendor
                && this.$data.item.vendor.id === 4
                && !this.$data.confirmed
            ) {
                if (!this.$data.item.id) { // only show checkout for new scripts
                    return this.$data.show_iab_billing_modal = true;
                } else{
                    this.$data.confirmed = true;
                    return this.submitted();

                }
            }

            if (
                this.$data.item.vendor
                && this.vendor_name() === 'ONVEGO'
                && this.$data.item.code === 19088
                && this.$data.item.appointments_enabled
                && typeof window.validateAppointmentsPanel === 'function'
            ) {
                const calendar_validation = await window.validateAppointmentsPanel();
                if (calendar_validation === 'validation-failed') {
                    const calendar = document.getElementById('onvego-calendar');
                    if (calendar) calendar.scrollIntoView({behavior: 'smooth', block: 'center'});
                    return null;
                }
            }

            this.$emit('submitted', this.$data.item);

            return true;
        },
        addContact() {
            const c = JSON.parse(JSON.stringify(this.$data.la.empty_contact));
            c._unique_key = randomNumber();
            this.$data.item.contacts.push(c);
        },
        addDestination(index) {
            const d = JSON.parse(JSON.stringify(this.$data.la.empty_destination));
            d._unique_key = randomNumber();
            this.$data.item.contacts[index].destinations.push(d);
            this.$forceUpdate();
        },
        removeContact(index) {
            if (index > -1) {
                if (this.$data.item.id) {
                    if (
                        this.$data.item.contacts[index]
                        && (
                           this.$data.item.contacts[index].is_default
                            || (
                                this.$data.item.contacts[index].id
                                && this.$data.item.default_contact_id === this.$data.item.contacts[index].id
                            )
                        )
                    ) {
                        this.$data.delete_default_contact = index;
                    }
                }
                if (this.$data.delete_default_contact === null) {
                    this.do_remove_contact(index);
                }
                this.$forceUpdate();
            }
        },
        do_remove_contact(index) {
            this.$data.item.contacts.splice(index, 1);
            this.$data.delete_default_contact = null;
            this.$forceUpdate();
        },
        async on_vendor_input(data) {
            if (data) {
                const chosen_service = data.id === 1 ? "Live Receptionist Service" : 'AI-Connect'
                this.$data.la.gtm_data_push('PDC_Chose_Service_Type', chosen_service);
            }
            this.$data.item.vendor = data;
            this.prefill_script_name(data.id);
            if (!this.$data.item.id) {
                if (this.vendor_name() === 'ONVEGO') {
                    this.$data.item.greeting = this.$lang.t('lr.thank-you-for-calling-company-name', 'Thank you for calling {}', [this.$session.user.account.contact.company]);
                } else if (
                    this.$session.user.account
                    && this.$session.user.account.contact
                    && this.$session.user.account.contact.company
                ) {
                    this.$data.item.greeting = this.$lang.t(
                        'lr.greeting-prefilled-text-',
                        'Thank you for calling {}. I\'m happy to connect your call. Which person or team would you like to reach today?',
                        [this.$session.user.account.contact.company]
                    );
                }
            }
            if (
                this.$data.item.vendor
                && this.$props.preselected_code
                && this.$data.item.vendor.codes
                && this.$data.item.vendor.codes.find((x) => x.code === this.$props.preselected_code)
            ) {
                this.$data.item.code = this.$props.preselected_code;
                this.on_codes_input(this.$data.item.code);
            } else if (
                this.$data.item.vendor
                && this.vendor_name() === 'ONVEGO'
                && !await getFeatureEnabled(this.$data.onvego_appointments_ft)
            ) {
                this.$data.item.code = 19087
                this.on_codes_input(this.$data.item.code); 
            }


            this.$forceUpdate();

            return true;
        },
        vendor_name() {
            if (this.$data.item?.vendor?.id) {
                return LiveAnswerVendor.vendor_group(this.$data.item?.vendor);
            }

            return null;
        },
         vendor_iframe_url() {
                return this.$data.item?.vendor?.iframeUrl
        },
        prefill_script_name(vendor_id) {
            if (!this.$data.item.name || [this.$data.la.virtual_generic_name, this.$data.la.live_generic_name].includes(this.$data.item.name)) {
                let name = '';
                if (vendor_id === 4) {
                    name = this.$data.la.virtual_generic_name;
                } else if (vendor_id === 1) {
                    name = this.$data.la.live_generic_name;
                }
                this.$data.item.name = name;
            }
        },
        on_disabled() {
            this.$data.item.enabled = false;
        },
        async reactivate_script() {
            this.$data.reactivate_confirmation_modal = false;
            await this.$data.la.reactivate();
        },
        check_break_point(xl, lg, md, sm, xs) { // only for firefox that doesnt support visible placeholder for v-textarea
            if (this.$vuetify.breakpoint.xl) return xl;
            if (this.$vuetify.breakpoint.lg) return lg;
            if (this.$vuetify.breakpoint.md) return md;
            if (this.$vuetify.breakpoint.sm) return sm;
            if (this.$vuetify.breakpoint.xs) return xs;
            return 14;
        },
        disable_input() {
            return !this.$data.item.enabled || this.$data.la.appointments_loading;
        }
    },
    watch: {
        reactivate_confirmation_modal(v) {
            if (!v) this.$data.confirmed = false;
        }
    },
    computed: {
        browser() {
            const {userAgent} = navigator;
            let browserName;
            if (userAgent.match(/chrome|chromium|crios/i)) {
                browserName = 'chrome';
            } else if (userAgent.match(/firefox|fxios/i)) {
                browserName = 'firefox';
            } else if (userAgent.match(/safari/i)) {
                browserName = 'safari';
            } else if (userAgent.match(/opr\//i)) {
                browserName = 'opera';
            } else if (userAgent.match(/edg/i)) {
                browserName = 'edge';
            } else {
                browserName = 'No browser detection';
            }
            return browserName;
        },
        default_contact_value() {
            const default_contact_index = this.$data.item.contacts.findIndex((x) => x.is_default);
            if (default_contact_index > -1) return default_contact_index;
            let value = null;
            if (this.$data.item.default_contact_id) {
                const script_default_contact_index = this.$data.item.contacts.findIndex((x) => x.id === this.$data.item.default_contact_id);
                if (script_default_contact_index > -1) {
                    value = script_default_contact_index;
                }
            }
            return value;
        }
    },
  };
</script>
<style lang="scss">
.live-receptionist {
    #lr-title {
        font-size: 19px !important;
    }
    #provider-explanation {
        position: relative;
        #close-provider {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    .provider-selector {
        margin-bottom: 30px;
    }
    .provider-divider {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .field-title {
        margin-top: 30px;
    }
    .field-title-ordinary {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .field-explanation {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .section-title {padding-left: 0};
    .live-answer-contacts-container {
        border: 1px solid rgba(133, 146, 154, 0.65);
        border-radius: 10px;
        padding: 15px 15px 30px 15px;
        margin-top: 20px;
    }
    .lr-subtitle {
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .text-card-title {
        font-size: 16px !important;
    }
    .card-text {
        font-size: 12px !important;
    }
}
</style>
